<template>
  <div
    class="relative grid items-center gap-2 px-4 py-3 transition-all duration-200 md:grid-cols-[1fr_auto_1fr] md:gap-2.5"
    :class="!currentMainMenuTitle ? 'grid-cols-[auto_1fr]' : 'grid-cols-[1fr_auto]'"
  >
    <div class="row-start-1 flex max-md:col-start-2 max-md:hidden">
      <div class="mr-auto rounded-2xl bg-[#191919]">
        <!-- <router-link class="mr-auto flex items-center gap-4" to="/swap">
          <img class="h-[50px]" src="/images/big_logo.png" alt="" />
          <img class="hidden h-4 md:block" src="/images/eldorado_logo_title.png" alt="" />
        </router-link> -->
        <MenuLink class="!w-auto" sub-panel-class="[&>div]:!py-2.5 absolute" title="">
          <template #icon>
            <div class="mr-1.5 flex items-center gap-2.5">
              <img class="h-6" src="/images/big_logo.png" alt="" />
              <img class="h-3.5" src="/images/eldorado_logo_title.png" alt="" />
              <div
                class="rounded-full border border-solid border-[#33FF99]/10 bg-[#33FF99]/10 px-2 py-1 text-[13px] leading-none text-[#33FF99]"
              >
                Beta
              </div>
            </div>
          </template>
        </MenuLink>
      </div>
    </div>
    <div
      class="row-start-1 flex max-md:col-start-1"
      :class="!walletCollapsedOnMobile ? 'max-md:invisible' : ''"
    >
      <MainMenu
        :menu-collapsed-on-mobile="menuCollapsedOnMobile"
        @toggle-menu="toggleMainMenu"
        @route-change="handleRouteChange"
      />
    </div>
    <div
      class="row-start-1 flex justify-end gap-2 max-md:col-start-2"
      :class="!menuCollapsedOnMobile ? 'max-md:invisible' : ''"
    >
      <TutorialHeader />
      <WalletMenu
        class="max-md:w-full"
        :menu-collapsed-on-mobile="walletCollapsedOnMobile"
        @toggle-menu="toggleWalletMenu"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import MainMenu from './Comp/MainMenu/MainMenu.vue'
import MenuLink from './Comp/MenuLink.vue'
import WalletMenu from './Comp/WalletMenu/WalletMenu.vue'
import TutorialHeader from './Comp/TutorialHeader.vue'

const menuCollapsedOnMobile = ref(true)
const toggleMainMenu = (isCollapsed: boolean) => {
  menuCollapsedOnMobile.value = isCollapsed
}
const walletCollapsedOnMobile = ref(true)
const toggleWalletMenu = (isCollapsed: boolean) => {
  walletCollapsedOnMobile.value = isCollapsed
}

const currentMainMenuTitle = ref()
const handleRouteChange = (title?: string) => {
  currentMainMenuTitle.value = title
}
</script>
